import { SubmitHandler } from 'react-hook-form'
import { FC } from 'react'

import { ZipCodeFormField } from 'components/form-field'
import { Form, useForm } from 'components/form'
import { SubmitFormButton } from 'components/submit-form-button'

import classes from './states-map-zip-form.module.css'
import { IZipFormProps } from './types'
import { FORM_FIELDS_NAME, FORM_VALIDATORS } from './consts'
import { Markdown } from '../markdown'

const TEXT_INFO = `
Enter your zip code to instantly see if Home Price Protection is available in your area and discover your potential safeguard in just a few seconds.

Don't let market shifts catch you off guard – act now!
`

interface StatesMapZipFormProps {
  onSubmit: SubmitHandler<IZipFormProps>
}

export const StatesMapZipForm: FC<StatesMapZipFormProps> = ({ onSubmit }) => {
  const formMethods = useForm<IZipFormProps>(FORM_VALIDATORS)

  return (
    <div>
      <Markdown className={classes.content}>{TEXT_INFO}</Markdown>
      <Form onSubmit={onSubmit} formMethods={formMethods} className={classes.form}>
        <ZipCodeFormField
          name={FORM_FIELDS_NAME.zipCode}
          label={null}
          fullWidth
          className={classes.field}
        />
        <SubmitFormButton theme="primary" className={classes.button}>
          Search
        </SubmitFormButton>
      </Form>
    </div>
  )
}
