import { Dispatch, SetStateAction } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { toast } from 'react-toastify'
import axios from 'axios'

import { yup } from 'components/form/yup'
import { FormNameType } from 'utils/types'
import { nest } from 'api'
import { GENERAL_FORM_ERROR_NAME } from 'components/form/FormGeneralError'

import { getPricingDataFromStorage } from '../utils'

interface ISearchModalData {
  amount: string
}

export const presets = ['$25,000', '$50,000', '$75,000', '$100,000']
export const max_value = 100000

export const FIELD: FormNameType<ISearchModalData> = {
  amount: 'amount',
}

export const CUSTOM_AMOUNT_SCHEMA = yup.object().shape({
  [FIELD.amount]: yup.string().required().min(5),
})

export const handlePresetClick = (presetValue: string, formMethods: UseFormReturn<any>) => {
  formMethods.setValue(FIELD.amount, presetValue)
}

export const convertToNumber = (val: string) =>
  Number(
    String(val)
      .split('')
      .filter((c) => c && c !== '$' && c !== ',')
      .join(''),
  )

export const getPricingData = async (amount: string, uuid: string | undefined) => {
  const amountNumber = convertToNumber(amount)

  if (!amount.length) {
    return
  }
  if (uuid) {
    try {
      const res = await nest.offerings.calculationPrice(uuid, amountNumber)
      if (res) return res
    } catch (err) {
      let errMessage = err
      if (axios.isAxiosError(err)) {
        const serverErrorMsg = err.response?.data?.errors?.maximumPayout
        if (serverErrorMsg) errMessage = serverErrorMsg
      }
      toast.error('Error: ' + errMessage)
    }
  }
}

const toCurrencyFormat = (num: number) => {
  return num.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  })
}

export const validatePayoutInput = (
  amount: string,
  formMethods: UseFormReturn<any>,
  minProtectionValue: number,
  marketName: string,
  setCalculateBtnState: Dispatch<SetStateAction<boolean>>,
) => {
  const amountNum = convertToNumber(amount)
  let isDataValid = true
  const minValue = toCurrencyFormat(minProtectionValue)
  const maxValue = toCurrencyFormat(max_value)

  if (amountNum < minProtectionValue) {
    formMethods.setError(FIELD.amount, {
      type: GENERAL_FORM_ERROR_NAME,
      message:
        'Protection amount can not be less than ' +
        minValue +
        ' for the ' +
        marketName.split(',')[0] +
        ' Market',
    })

    isDataValid = false
  }

  if (amountNum > max_value) {
    formMethods.setError(FIELD.amount, {
      type: GENERAL_FORM_ERROR_NAME,
      message: 'Protection amount can not be more than ' + maxValue,
    })
    isDataValid = false
  }

  if (isDataValid) {
    formMethods.clearErrors(FIELD.amount)
  }
  setCalculateBtnState(!isDataValid)
}

export const getCustomPayout = () => {
  const calculationsModalData = getPricingDataFromStorage()
  if (!calculationsModalData) return null
  const { protectionAmount } = calculationsModalData

  return protectionAmount
}
