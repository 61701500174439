import { ReactNode, FC } from 'react'

import { Heading } from 'components/heading'
import { cn } from 'utils'

import classes from './conent-header.module.css'

interface ContentHeaderProps {
  title?: string
  text: ReactNode
  additionalInfo?: ReactNode
  className?: string
  classNameTitle?: string
}

export const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  text,
  additionalInfo,
  className,
  classNameTitle,
}) => {
  return (
    <header className={cn(title && classes.header, className)}>
      {title && (
        <Heading
          theme="h2"
          component="h3"
          className={cn(
            classes.title,
            classNameTitle,
            !!additionalInfo && classes.titleWithAdditionalInfo,
          )}
        >
          {title}
        </Heading>
      )}
      {additionalInfo && <strong className={classes.additionalInfo}>{additionalInfo}</strong>}
      {text}
    </header>
  )
}
