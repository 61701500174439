import { FormNameType } from 'utils//types'
import { yup } from 'components/form/yup'
import { ZIP_CODE_RULE } from 'components/form-field'

import { IZipFormProps } from './types'

export const FORM_FIELDS_NAME: FormNameType<IZipFormProps> = {
  zipCode: 'zipCode',
}

export const FORM_VALIDATORS = yup.object().shape({
  [FORM_FIELDS_NAME.zipCode]: ZIP_CODE_RULE.required(),
})
