import { FC, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { useQuery } from '@tanstack/react-query'

import { useDialogContext } from 'components/dialog/utils'
import { nest } from 'api'
import { WaitingContent } from 'components/search-markets-by-zip-code/waiting-content'
import { withRouterDialog } from 'components/dialog'
import { DIALOG_NAME } from 'const/dialogs'
import { MarketModel } from 'api/nest'
import { NotAvailableContent } from 'components/not-available-dialog'

import { PriceCalculations } from './price-calculations'
import { QUERY_KEY } from '../../const/query'
import { AvailableSingleMarket } from './available-market'

interface SearchMarketsByZipCodeProps {
  zipCode: string
  isPriceCalculationsModal?: boolean
  selectMarketButtonText?: string
  onSelectMarket?: (market: MarketModel) => void
  openCalculationsModal?: () => void
}

export const _SearchMarketsByZipCode: FC<SearchMarketsByZipCodeProps> = ({
  zipCode,
  isPriceCalculationsModal = false,
  selectMarketButtonText = '',
  onSelectMarket = () => null,
  openCalculationsModal = () => null,
}) => {
  const [isDoneWaitingTimer, setIsDoneWaitingTimer] = useState(false)
  const { close } = useDialogContext()
  const { isLoading, data } = useQuery(
    [QUERY_KEY.searchMarketByZipCode, zipCode],
    () => nest.markets.findByZipCode(zipCode),
    {
      refetchOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
  )

  useEffectOnce(() => {
    if (!zipCode) close()
  })

  if (isLoading || !isDoneWaitingTimer) {
    return <WaitingContent onDoneTimer={() => setIsDoneWaitingTimer(true)} />
  }

  const marketsLength = data?.length

  if (!marketsLength) {
    return <NotAvailableContent searchZipCode={zipCode} />
  }

  return isPriceCalculationsModal ? (
    <PriceCalculations
      market={data[0]!}
      zipCode={zipCode}
      openCalculationsModal={openCalculationsModal}
    />
  ) : (
    <AvailableSingleMarket
      market={data[0]!}
      zipCode={zipCode}
      selectMarketButtonText={selectMarketButtonText}
      onSelectMarket={onSelectMarket}
    />
  )
}

export const SearchMarketsByZipCode = withRouterDialog(_SearchMarketsByZipCode, {
  modalName: DIALOG_NAME.searchMarketByZipCode,
  theme: 'onlyContent',
  withoutHeader: true,
})
