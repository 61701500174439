import { ReactNode, FC, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { nest } from 'api'
import { routes } from 'utils/routes'
import { MarketModel, MarketModelByZipCode } from 'api/nest'
import { Button } from 'components/button'
import { BuyNowButton } from 'components/buy-now-button'
import { ContentHeader } from 'components/search-markets-by-zip-code/content-header'
import { UsStateEnum } from 'enum/us-state'

import classes from './available-market.module.css'

export interface PrimaryButtonOptions {
  selectMarketButtonText: string
  onSelectMarket: (market: MarketModel) => void
}

interface AvailableSingleMarketProps extends PrimaryButtonOptions {
  market: MarketModelByZipCode
  zipCode: string
}

const description_text =
  'Great news! Home Price Protection is available in your area. The zip code you entered'

const getDescription = (marketName: string, zipCode: string): ReactNode => (
  <>
    {description_text} <strong className={classes.accent}>{zipCode}</strong> falls within the{' '}
    <strong className={classes.accent}>{marketName}</strong> Market.
  </>
)

export const AvailableSingleMarket: FC<AvailableSingleMarketProps> = ({
  market,
  selectMarketButtonText,
  zipCode,
}) => {
  const { push } = useRouter()
  const [state, setState] = useState<UsStateEnum | null>(null)

  useEffect(() => {
    nest.postalCodes.findByPostalCode(zipCode).then((postalCode) => {
      if (postalCode.state) setState(postalCode.state)
    })
  }, [zipCode])

  return (
    <div>
      <ContentHeader title="Select your market" text={getDescription(market.name, zipCode)} />
      <div className={classes.buttonsWrapper}>
        <Button
          onClick={() => {
            push(routes.market(market.slug))
          }}
          theme="primaryLight"
          className={classes.button}
        >
          MARKET DASHBOARD
        </Button>
        <BuyNowButton
          toIgnoreSessionRole={true}
          disabled={state === null}
          theme="primary"
          className={classes.button}
          flowStorageData={{
            market: {
              state: state!,
              canEditMaxPayout: market?.canEditMaxPayout,
              defaultMaxPayout: market?.defaultMaxPayout,
              defaultOfferingUuid: market?.defaultOffering?.uuid,
              uuid: market.uuid,
              zipCode: zipCode,
            },
          }}
        >
          {selectMarketButtonText}
        </BuyNowButton>
      </div>
    </div>
  )
}
