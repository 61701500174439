import { OfferingCalculationPriceResponseModel } from 'api/nest/offerings/types'
import { MarketModelByZipCode } from 'api/nest'

export interface ICalculationsData {
  pricingData: OfferingCalculationPriceResponseModel
  market: MarketModelByZipCode
  zipCode: string
  protectionAmount: number
}

export function getPricingDataFromStorage(): ICalculationsData | null {
  try {
    const data = sessionStorage.getItem('pricingCalculation')
    if (data) {
      const parsedData = JSON.parse(data) as ICalculationsData
      return parsedData
    }
  } catch (err) {}

  return null
}
